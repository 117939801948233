*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

html {
  /* Sets rem to 10px */
  font-size: 62.5%;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #393939;
}

.app {
  flex: 1;
}

a {
  text-decoration: none;
}

.wrapper {
  width: 83.5vw;
  max-width: 128rem;
  margin: 0 auto;
}
.wrapper--lead {
  width: 92rem;
}
.wrapper--sub {
  max-width: 100rem;
}

.absolute-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
}

.center-auto {
  margin: 0 auto;
}

.align--center {
  text-align: center;
}
.align--left {
  text-align: left;
}
.align--right {
  text-align: right;
}

.align-vertical {
  display: flex;
  align-items: center;
}

.align-end {
  align-self: flex-end;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.reverse-md {
  flex-direction: row;
}

.float--left {
  float: left;
}
.float--right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.inline-block {
  display: inline;
}

.block {
  display: block;
}

.app {
  font-size: 1.6rem;
}

.np {
  padding: 0;
}

.p-2rem {
  padding: 2rem;
}

.nm {
  margin: 0;
}

.mt-1rem {
  margin-top: 1rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mt-4rem {
  margin-top: 4rem;
}

.mt-5rem {
  margin-top: 5rem;
}

.mt-6rem {
  margin-top: 6rem;
}

.mt-10rem {
  margin-top: 10rem;
}

.mt-11rem {
  margin-top: 11rem;
}

.mt-12rem {
  margin-top: 12rem;
}

.mt-13rem {
  margin-top: 13rem;
}

.mt-15rem {
  margin-top: 15rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.mb-4rem {
  margin-bottom: 4rem;
}

.mb-5rem {
  margin-bottom: 5rem;
}

.mb-6rem {
  margin-bottom: 6rem;
}

.mb-10rem {
  margin-bottom: 10rem;
}

.mb-11rem {
  margin-bottom: 11rem;
}

.mb-12rem {
  margin-bottom: 12rem;
}

.mb-13rem {
  margin-bottom: 13rem;
}

.ml-1rem {
  margin-left: 1rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.ml-3rem {
  margin-left: 3rem;
}

.ml-4rem {
  margin-left: 4rem;
}

.ml-5rem {
  margin-left: 5rem;
}

.mr-1rem {
  margin-right: 1rem;
}

.mr-2rem {
  margin-right: 2rem;
}

.mr-3rem {
  margin-right: 3rem;
}

.mr-4rem {
  margin-right: 4rem;
}

.mr-5rem {
  margin-right: 5rem;
}

.pl-1rem {
  margin-left: 1rem;
}

.pl-2rem {
  margin-left: 2rem;
}

.pl-3rem {
  margin-left: 3rem;
}

.pl-4rem {
  margin-left: 4rem;
}

.pl-5rem {
  margin-left: 5rem;
}

.pr-1rem {
  margin-right: 1rem;
}

.pr-2rem {
  margin-right: 2rem;
}

.pr-3rem {
  margin-right: 3rem;
}

.pr-4rem {
  margin-right: 4rem;
}

.pr-5rem {
  margin-right: 5rem;
}

.h-spacer {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.v-spacer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.width-100 {
  width: 100%;
}

.label {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Proxima Nova Semibold";
  font-weight: bold;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(#ebebeb, #ebebeb);
}

.pointer {
  cursor: pointer;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/Proxima Nova Regular.otf");
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../fonts/Proxima Nova Semibold.otf");
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../fonts/Proxima Nova Bold.otf");
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 100;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 1.6rem;
}

.text--small {
  font-size: 1rem;
}
.text--muted {
  color: #8b8b8b;
}
.text--white {
  color: #fff;
}
.text--blue {
  color: #40b1fe;
}
.text--proceed {
  color: #20d670;
}
.text--danger {
  color: #ff5555;
}
.text--aqua {
  color: #27ce8f;
}
.text--upper {
  text-transform: uppercase;
}
.text--404 {
  font-size: 7rem;
}

.btn {
  border: solid 1px transparent;
  border-radius: 7px;
  padding: 1.5rem 2rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 2px;
  word-wrap: normal;
}
.btn a {
  color: #40b1fe;
}
.btn:focus {
  outline: 0;
}
.btn--white {
  border: solid 1px #27ce8f;
  background-color: #fff;
  color: #27ce8f;
  transition: 0.5s;
}
.btn--white:hover {
  background-color: #27ce8f;
  color: #fff;
  cursor: pointer;
}
.btn--white-border {
  border: solid 1px #fff;
  background-color: transparent;
  color: #fff;
  transition: 0.5s;
}
.btn--white-border:hover {
  background-color: #fff;
  color: #27ce8f;
  cursor: pointer;
}
.btn--buy {
  display: block;
  width: 100%;
}
.btn--no-case {
  text-transform: none;
}
.btn--gradient-primary {
  border-radius: 5px;
  background-image: linear-gradient(270deg, #27ce8f -20%, #29c1e8 118.95%);
  color: #fff;
  transition: all 0.5s;
}
.btn--gradient-primary:hover {
  background-image: linear-gradient(to left, #29c1e8, #27ce8f);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.12);
  color: #4185e4;
  cursor: pointer;
}
.btn--gradient-border {
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #29c1e8 0%, #27ce8f 100%);
  background-color: #fff;
  border-image-slice: 1;
  border-image-repeat: round;
  color: #40b1fe;
  transition: 0.5s;
}
.btn--gradient-border:hover {
  background-image: linear-gradient(to right, #29c1e8 0%, #27ce8f 100%);
  color: #fff;
  box-shadow: 2px 2px 4px 2px #ebebeb;
  cursor: pointer;
}
.btn--proceed {
  background-color: #20d670;
  border: 1px solid #20d670;
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  transition: 0.5s;
}
.btn--proceed:hover {
  background-color: #2ebb6b;
  border: 1px solid #2ebb6b;
  box-shadow: 2px 2px 4px 2px #ebebeb;
  cursor: pointer;
}
.btn--danger {
  background-color: #ff5555;
  border: 1px solid #ff5555;
  border-radius: 4px;
  padding: 1rem;
  margin-right: 1rem;
  color: #fff;
  transition: 0.5s;
}
.btn--danger:hover {
  background-color: #d34545;
  border: 1px solid #d34545;
  box-shadow: 2px 2px 4px 2px #ebebeb;
  cursor: pointer;
}
.btn--dashed {
  background-color: #f7fbff;
  border-color: #cde0f9;
  color: #b8d4f8;
  border-style: dashed;
}
.btn--aqua-two {
  background-color: #27ce8f;
  border: 1px solid #27ce8f;
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  transition: 0.5s;
}
.btn--aqua-two:hover {
  background-color: #009b9c;
  border: 1px solid #009b9c;
  cursor: pointer;
}
.btn--custom-one {
  background-color: #13212d;
  border: 1px solid #13212d;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  letter-spacing: 3px;
  color: #fff;
  font-size: large;
  transition: 0.5s;
}
.btn--custom-one:hover {
  background-color: #444053;
  border: 1px solid #444053;
  cursor: pointer;
}
.btn--custom-two {
  background-color: #13212d;
  border: 1px solid #13212d;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  letter-spacing: 3px;
  color: #fff;
  font-size: small;
  transition: 0.5s;
}
.btn--custom-two:hover {
  background-color: #444053;
  border: 1px solid #444053;
  cursor: pointer;
}
.btn--blue {
  background-color: #40b1fe;
  color: #fff;
  transition: 0.5s;
}
.btn--blue:hover {
  background-color: #4185e4;
  box-shadow: 2px 2px 4px 2px #ebebeb;
  cursor: pointer;
}
.btn--grey {
  border: solid 1px #d5d5d5;
  background-color: transparent;
  color: #d5d5d5;
}
.btn--muted {
  border: solid 1px #d5d5d5;
  background-color: #d5d5d5;
  color: #fff;
}
.btn--add {
  padding: 1rem;
  background-color: #51b7ff;
  border-radius: 4px;
  border: solid 1px #40b1fe;
  border-style: dashed;
}
.btn--facebook {
  color: #fff;
  background-color: #4460a0;
}
.btn--force-height {
  height: 3.4rem;
}
.btn--width {
  transition: all 0.5s;
}
.btn--width-sm {
  width: 10rem;
}
.btn--width-md {
  width: 15rem;
}
.btn--width-lng {
  border-radius: 3px;
  padding: 0.5rem 10rem 0.5rem 10rem;
  font-weight: 900;
}
.btn--width-auto {
  width: auto;
}

.btn_normal {
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  box-shadow: 2px 2px 44px rgba(165, 165, 165, 0.25);
}
.btn_gradient {
  color: #fff;
  background: linear-gradient(270deg, #27ce8f -20%, #29c1e8 118.95%);
  transition: all 0.5s;
}
.btn_gradient:hover {
  background: linear-gradient(to left, #29c1e8, #27ce8f);
  box-shadow: 2px 2px 4px 2px rgba(165, 165, 165, 0.25);
}
.btn_dark {
  color: #fff;
  background: #004241;
  transition: all 0.5s;
}
.btn_dark:hover {
  background: #009b9c;
}
.btn_green {
  background: #009b9c;
  color: #fff;
  transition: all 0.5s;
}
.btn_green:hover {
  color: #009b9c;
  background: #fff;
}
.btn_white {
  background: #fff;
  color: #009b9c;
  transition: all 0.5s;
}
.btn_white img {
  max-width: 15px;
  margin-left: 0.3rem;
}
.btn_white:hover {
  background: #009b9c;
  color: #fff;
}
.btn_md {
  padding: 1rem 2rem;
}

.dashboard_nav_side {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 20%;
}
.dashboard_nav_top {
  width: 80%;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}
.dashboard_page {
  width: 80%;
  margin-left: 20%;
  margin-top: 10rem;
  margin-bottom: 0;
}
.dashboard_navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}
.dashboard_navbar_left {
  display: none;
}
.dashboard_navbar_right {
  cursor: pointer;
  padding: 2rem;
  padding-right: 3rem;
}
.dashboard_navbar_right img {
  margin-right: 0.5rem;
}
.dashboard_sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
}
.dashboard_sidebar img {
  cursor: pointer;
  margin: 2rem;
  width: 15rem;
}
.dashboard_sidebar_btn {
  margin: 2rem 0 0 0;
  width: 100%;
}
.dashboard_sidebar_menu {
  height: 100vh;
}
.dashboard_home {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}
.dashboard_home_overview {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard_home_overview_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 25rem;
  background-color: rgba(41, 198, 201, 0.26);
  border-radius: 10px;
  padding: 2rem 3rem;
  cursor: pointer;
}
.dashboard_home_overview_card h2 {
  color: #004241;
}
.dashboard_home_overview_card_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.dashboard_home_overview_card_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 2.5rem;
}
.navbar__contact {
  font-size: 1.2rem;
  margin-left: 3rem;
}
.navbar__link {
  color: #666666;
  transition: 0.5s;
}
.navbar__link:not(:last-child) {
  margin-right: 4.5rem;
}
.navbar__link--active {
  color: #40b1fe;
  text-decoration: none;
}
.navbar__link:hover {
  color: #d5d5d5;
}
.navbar--landing {
  color: #fff;
}

.sidenav {
  z-index: 200;
  padding-top: 4rem;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #d5d5d5;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  max-width: 30rem;
}
.sidenav__items {
  margin-left: 3rem;
}
.sidenav__item {
  list-style: none;
  width: 85%;
  color: #29c1e8;
  padding: 1.5rem;
  transition: color 0.5s;
}
.sidenav__item:not(:last-child) {
  border-bottom: 1px solid #d5d5d5;
}
.sidenav__item a {
  color: #29c1e8;
}
.sidenav__item:hover {
  color: #40b1fe;
}

.header {
  padding-bottom: 10rem;
}
.header--ribbon::after {
  display: block;
  position: relative;
  background-image: -webkit-linear-gradient(left, #20d670, #40b1fe);
  background-image: linear-gradient(to right, #20d670, #40b1fe);
  content: "";
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  top: 10rem;
  width: 100%;
  height: 0.55rem;
}
.header--landing {
  height: 25rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 20rem;
}
.sidebar__items {
  list-style: none;
  text-transform: uppercase;
  color: #d5d5d5;
}
.sidebar__item {
  display: flex;
  align-items: center;
  width: 20rem;
  font-size: 12px;
  padding: 4px 0;
  margin-bottom: 1.8rem;
}
.sidebar__item:hover {
  color: #27ce8f;
  cursor: pointer;
}
.sidebar__item--active {
  color: #27ce8f;
  border-right: solid 2px #29c1e8;
}

.site-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.site-content--spaced {
  display: flex;
  justify-content: space-between;
}
.site-content__lead {
  margin-bottom: 0.5rem;
}
.site-content__lead--left {
  text-align: left;
}
.site-content__lead--right {
  text-align: right;
}
.site-content__subtitle {
  font-size: 1.6rem;
  font-weight: 100;
  color: #a0a0a0;
}
.site-content__muted {
  padding: 5rem 0;
  width: 100%;
  background-color: #fafafa;
}
.site-content__selling-points {
  display: flex;
  flex-direction: row;
  width: 96rem;
}
.site-content__selling-point {
  line-height: 1.6;
  display: flex;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 21rem;
  width: 45rem;
  padding: 2.5rem 4rem 4rem 4rem;
  margin: 1rem;
  transition: 0.5s;
}
.site-content__selling-point img {
  align-self: flex-start;
  margin-top: 1rem;
  min-width: 30px;
}
.site-content__selling-point:hover {
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.12);
}
.site-content__navigation {
  display: flex;
  flex-direction: row;
}

.selling-point__title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero {
  max-width: 50%;
  color: #fff;
  z-index: 2;
}
.hero__content {
  max-width: 60rem;
  line-height: 3rem;
}

.bq-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 100%;
  background-image: linear-gradient(to right, #29c1e8, #27ce8f);
}
.bq-banner__items {
  display: flex;
  flex-direction: row;
  width: 60vw;
  justify-content: space-between;
  align-items: center;
}
.bq-banner__item {
  list-style: none;
  color: #fff;
  display: flex;
  align-items: center;
}
.bq-banner__item img {
  margin-right: 1rem;
  max-height: 2rem;
  height: auto;
}

.illo {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.illo img {
  flex: 0 0 auto;
  width: auto;
}

.bq-logo {
  height: auto;
  width: auto;
}
.bq-logo--navbar {
  max-width: 10rem;
  max-height: 10rem;
  transform: translateY(9%);
}
.bq-logo--footer {
  max-width: 6.5rem;
  max-height: 6.5rem;
}
.bq-logo--auth {
  max-width: 9rem;
  max-height: 9rem;
}

.contact-icon {
  max-height: 1.3rem;
  height: auto;
  width: auto;
}

.insurer-logo {
  height: auto;
  width: auto;
  max-height: 3.5rem;
  display: block;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding: 8rem 5rem 3rem 5rem;
  color: #8fa3aa;
  background-color: #121e27;
  line-height: 1.7rem;
  margin: 0;
}
.footer__logo {
  cursor: pointer;
}
.footer__logo img {
  max-width: 12rem;
  max-height: 12rem;
}
.footer__tier1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 2rem;
}
.footer__tier1__detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 5rem;
}
.footer__tier1__detail__contact {
  font-size: 1.3rem;
  margin: 3rem 0;
}
.footer__tier1__detail__contact h4 {
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
}
.footer__tier1__detail__contact p a {
  color: #f2f2f2;
}
.footer__tier1__link {
  color: #8fa3aa;
  cursor: pointer;
  font-size: 1.3rem;
  transition: 0.5s;
  line-height: 2rem;
}
.footer__tier1__link-group {
  display: flex;
  flex-direction: column;
  margin: 0rem 7rem 3rem 0;
}
.footer__tier1__link-group h4 {
  font-weight: bold;
  color: #fff;
  margin-bottom: 2.5rem;
}
.footer__tier1__link:hover {
  color: #40b1fe;
  text-decoration: underline;
}
.footer__tier2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__tier2__links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.7rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.footer__tier2__links__separator {
  margin: 0 2rem 0 2rem;
}
.footer__tier2__links__link a {
  color: #8fa3aa;
}
.footer__tier2__links__link:hover {
  color: #40b1fe;
  text-decoration: underline;
}
.footer__tier2 hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #f2f2f2, #fff, #f2f2f2);
  width: 70%;
}
.footer__legal {
  text-align: center;
  font-size: 1.5rem;
}
.footer hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(95, 95, 95, 0.5), rgba(0, 0, 0, 0));
}

.social__items {
  display: flex;
  flex-direction: row;
}
.social__items img {
  max-height: 2rem;
}
.social__item {
  max-height: 2rem;
}

.contact--email img {
  margin-right: 0.5rem;
}
.contact--phone img {
  margin-right: 0.5rem;
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  min-height: 100vh;
  width: 100%;
}
.auth__top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.auth__bounding-box {
  padding: 3rem 5rem;
  border-radius: 5px;
  width: 45rem;
  height: auto;
  background: #fff;
  border: 1px solid #ebebeb;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.auth__caption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
}
.auth__caption p {
  color: #004241;
  font-weight: 800;
  font-size: 2.5;
}
.auth__caption small {
  color: rgba(0, 66, 65, 0.69);
}
.auth__caption__box {
  font-size: 1.1rem;
  font-weight: 500 !important;
  margin-bottom: 1rem;
  color: rgba(0, 56, 56, 0.38) !important;
  background: #f2f2f2;
  padding: 1rem 1rem 1rem 0.5rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-width: 0 0 0 10px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #29c1e8, #27ce8f) 1 100%;
  padding: 1rem;
}
.auth__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.user-dash__main {
  width: 80%;
  padding: 0 4rem;
  margin-left: 10%;
}

.user-dash-card {
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  width: 25rem;
  height: 12.7rem;
  padding: 2rem;
  position: relative;
  transition: 0.5s;
}
.user-dash-card__category {
  font-size: 1.2rem;
}
.user-dash-card__status {
  border-radius: 2px;
  color: #fff;
  font-size: 0.7rem;
  padding: 0.5rem 0.6rem;
  text-transform: uppercase;
}
.user-dash-card__status--processed {
  background: #27ce8f;
}
.user-dash-card__status--cancelled {
  background: #ff5555;
}
.user-dash-card__status--requested {
  background: #ff5555;
}
.user-dash-card__status--in-progress {
  background: #f5a623;
}
.user-dash-card__description {
  font-size: 1rem;
}
.user-dash-card__premium {
  color: #27ce8f;
}
.user-dash-card__logo {
  max-height: 2.5rem;
}
.user-dash-card__options-toggle {
  cursor: pointer;
  transform: rotate(90deg);
}
.user-dash-card__options {
  list-style: none;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1rem;
  padding: 1rem;
  position: absolute;
  z-index: 100;
  top: 4rem;
  right: 2rem;
  width: 10rem;
}
.user-dash-card__option {
  cursor: pointer;
}
.user-dash-card__option:not(:last-child) {
  margin-bottom: 1rem;
}

.user-dash-card:hover {
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.12);
}

.motor_floating_section {
  display: none;
}
.motor_form_section {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.motor_partners_section {
  background-color: rgba(41, 198, 201, 0.26);
  padding-top: 3rem;
  padding-left: 5rem;
  padding-bottom: 10rem;
}
.motor_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  margin-top: 3rem;
  background-color: #fff;
  box-shadow: 2px 2px 40px 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 3rem;
  transition: all 0.5s ease-in-out;
}
.motor_form h3 {
  color: #004241;
  text-align: center;
}
.motor_form h4 {
  color: #004241;
  text-align: center;
}
.motor_form_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: ease-in-out;
}
.motor_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5rem;
}
.motor_banner img {
  margin-top: 3rem;
}
.motor_banner_title {
  color: #004241;
  font-weight: 400;
}
.motor_banner_title_ {
  color: #009b9c;
}
.motor_banner_title_price {
  font-weight: 900;
}
.motor_banner_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  margin-bottom: 5rem;
}
.motor_banner_tag1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(41, 198, 201, 0.26);
  padding: 1rem 2rem;
  border-radius: 17px;
  margin-right: 3rem;
}
.motor_banner_tag1 img {
  margin-top: 0rem;
}
.motor_banner_tag1 p {
  color: #004241;
}
.motor_banner_tag1 span {
  color: rgba(0, 66, 65, 0.69);
}
.motor_banner_tag2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(41, 198, 201, 0.26);
  padding: 1rem 2rem;
  border-radius: 17px;
}
.motor_banner_tag2 img {
  margin-top: 0rem;
}
.motor_banner_tag2 p {
  color: #004241;
}
.motor_banner_tag2 span {
  color: rgba(0, 66, 65, 0.69);
}
.motor_partners {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.motor_partners_title {
  color: #004241;
}
.motor_partners_subtitle {
  color: rgba(0, 66, 65, 0.69);
}
.motor_partners_logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.motor_partners_logo {
  width: 15rem;
  padding: 3rem;
  margin: 3rem;
  background-color: #fff;
}
.motor_partners_logo img {
  width: 100%;
}

.motor_compare {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.motor_compare_header {
  margin-top: 10rem;
  margin-bottom: 5rem;
}
.motor_compare_header h2 {
  text-align: center;
  color: #004241;
  padding: 0 2rem;
}
.motor_compare_card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  margin-bottom: 3rem;
  margin-top: 16rem;
  box-shadow: 2px 2px 40px 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background: linear-gradient(to right, #29c1e8, #27ce8f);
  position: absolute;
}
.motor_compare_card h3 {
  color: #fff;
}
.motor_compare_card p {
  color: #d5d5d5;
}
.motor_compare_policies {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 13rem;
  padding: 5rem;
  background-color: rgba(41, 198, 201, 0.26);
  width: 100%;
}
.motor_compare_policies_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
.motor_compare_policies_warning img {
  max-width: 20px;
  margin-right: 0.5rem;
  padding-top: 0;
}
.motor_compare_policies_warning p {
  padding-top: 1rem;
  color: #004241;
}
.motor_compare_policies_list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.motor_compare_policies_list_card_mobile {
  display: none;
}
.motor_compare_policies_list_help_mobile {
  display: none;
}

.policy_card {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 2px 2px 44px rgba(165, 165, 165, 0.25);
  width: 32rem;
  margin: 3rem;
}
.policy_card_excerpt_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.policy_card_excerpt_company {
  display: flex;
}
.policy_card_excerpt_company img {
  width: 7rem;
}
.policy_card_excerpt_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.policy_card_excerpt_details p {
  color: rgba(0, 66, 65, 0.69);
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.policy_card_excerpt_details img {
  width: 20px;
}
.policy_card_excerpt_details:hover {
  color: rgba(0, 66, 65, 0.69);
}
.policy_card_excerpt_pricing {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.policy_card_excerpt_pricing h1 {
  color: #004241;
  font-weight: 900;
  font-size: 3rem;
}
.policy_card_excerpt_cta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
}
.policy_card_excerpt_cta button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 2px 2px 44px rgba(165, 165, 165, 0.25);
}
.policy_card_excerpt_cta1 {
  background: #fff;
  color: #009b9c;
  transition: all 0.5s;
}
.policy_card_excerpt_cta1 img {
  max-width: 15px;
  margin-left: 0.3rem;
}
.policy_card_excerpt_cta1:hover {
  background: #009b9c;
  color: #fff;
}
.policy_card_excerpt_cta2 {
  background: #fff;
  color: #ff5555;
  transition: all 0.5s;
}
.policy_card_excerpt_cta2 img {
  max-width: 15px;
  margin-left: 0.3rem;
}
.policy_card_excerpt_cta2:hover {
  background: #ff5555;
  color: #fff;
}
.policy_card_excerpt_cta3 {
  background: #009b9c;
  color: #fff;
  position: relative;
  left: 0;
  transition: all 0.5s;
}
.policy_card_excerpt_cta3:hover {
  color: #009b9c;
  background: #fff;
}

.get_help_card {
  display: flex;
  flex-direction: row;
}
.get_help_card img {
  max-height: 20rem;
}
.get_help_card_caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f2f2f2;
  background-color: #009b9c;
  padding: 1rem 2rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.get_help_card_caption h2 {
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 2rem;
  padding-bottom: 0;
  color: #f2f2f2;
}

.travel_page {
  height: auto;
}
.travel_page_floating {
  display: none;
}
.travel_page_form {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.travel_page_partners {
  background-color: rgba(41, 198, 201, 0.26);
  padding: 3rem 5rem;
  padding-bottom: 10rem;
}
.travel_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  background-color: #fff;
  box-shadow: 2px 2px 40px 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 3rem;
  transition: all 0.5s ease-in-out;
}
.travel_form h3 {
  color: #004241;
  text-align: center;
}
.travel_form h4 {
  color: #004241;
  text-align: center;
}
.travel_form_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: ease-in-out;
}
.travel_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5rem;
  margin-bottom: 5rem;
}
.travel_banner_title {
  color: #004241;
  font-weight: 400;
  margin: 3rem 3rem;
}
.travel_banner_title_ {
  color: #009b9c;
}
.travel_banner img {
  margin-top: 2rem;
}
.travel_banner_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
}
.travel_banner_tag_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}
.travel_banner_tag_item p {
  margin: 1rem;
  margin-top: 2rem;
  font-size: 1.3rem;
}
.travel_partners {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.travel_partners_title {
  color: #004241;
}
.travel_partners_subtitle {
  color: rgba(0, 66, 65, 0.69);
}
.travel_partners_logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.travel_partners_logo {
  width: 15rem;
  padding: 3rem;
  margin: 3rem;
  background-color: #fff;
}
.travel_partners_logo img {
  width: 100%;
}

.travel_compare {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.travel_compare_header {
  margin-top: 10rem;
  margin-bottom: 5rem;
}
.travel_compare_header h2 {
  text-align: center;
  color: #004241;
  padding: 0 2rem;
}
.travel_compare_card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  margin-bottom: 3rem;
  margin-top: 16rem;
  box-shadow: 2px 2px 40px 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background: linear-gradient(to right, #29c1e8, #27ce8f);
  position: absolute;
}
.travel_compare_card h3 {
  color: #fff;
}
.travel_compare_card p {
  color: #d5d5d5;
}
.travel_compare_policies {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  background-color: rgba(41, 198, 201, 0.26);
  width: 100%;
}
.travel_compare_policies_cta {
  position: fixed;
  z-index: 1000;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  background-color: #fff;
  box-shadow: 2px 2px 44px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2rem;
  color: rgba(0, 66, 65, 0.69);
  font-size: 1.8rem;
  font-weight: 700;
}
.travel_compare_policies_cta_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
}
.travel_compare_policies_cta span {
  color: #009b9c;
}
.travel_compare_policies_cta_mobile {
  display: none;
}
.travel_compare_policies_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.travel_compare_policies_warning img {
  max-width: 20px;
  margin-right: 0.5rem;
  padding-top: 0;
}
.travel_compare_policies_warning p {
  padding-top: 1rem;
  color: #004241;
  font-weight: 700;
  font-size: 2rem;
}
.travel_compare_policies_list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.travel_compare_policies_list_card_mobile {
  display: none;
}
.travel_compare_policies_list_help_mobile {
  display: none;
}
.travel_compare_page table th {
  font-weight: 900;
  font-size: 1.5rem;
  min-width: 15rem;
  color: #004241;
}
.travel_compare_page table td {
  color: rgba(0, 66, 65, 0.69);
  text-align: center;
}
.travel_compare_page_first_column {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: #fff;
}

.onboarding {
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  position: relative;
  text-align: center;
}
.onboarding__container {
  position: absolute;
  top: 15vh;
  min-width: 60vw;
}
.onboarding__heading {
  text-transform: uppercase;
  font-family: "Proxima Nova Semibold";
}
.onboarding__description {
  color: #ebebeb;
  margin: 1.5rem;
}
.onboarding__description--small {
  display: block;
  text-align: left;
}
.onboarding__label {
  display: block;
  text-align: left;
  text-transform: uppercase;
}
.onboarding__label--underline {
  border-bottom: solid 1px #666666;
  padding-bottom: 1rem;
  margin-bottom: 3.2rem;
}

.onboarding__input {
  border: 1px solid #add2f9;
  background-color: #f7fbff;
}

.onboarding-product {
  background-color: #f7fbff;
  border: solid 1px #40b1fe;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 20rem;
  width: 20rem;
  position: relative;
  text-transform: uppercase;
}
.onboarding-product--create {
  cursor: pointer;
  border-style: dashed;
}
.onboarding-product__img {
  display: flex;
  justify-content: center;
  max-height: 40px;
  max-width: 40px;
}
.onboarding-product__type {
  color: #559eff;
  font-size: 1rem;
}
.onboarding-product__details {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #40b1fe;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1rem 1rem;
  font-size: 1rem;
}
.onboarding-product__detail {
  color: #8b8b8b;
}
.onboarding-product__name {
  display: block;
  font-size: 1.1rem;
}

.onboarding-staff {
  width: 33%;
  float: left;
}

.policy {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  min-height: 41rem;
  width: 28rem;
  font-size: 1.4rem;
  overflow: hidden;
  transition: 0.3s;
}
.policy__header {
  height: 8.5rem;
}
.policy__info {
  margin: 1.5rem 1.5rem 0 1.5rem;
  height: 12rem;
  text-align: left;
  position: relative;
}
.policy__info--breakdown {
  height: 6rem;
}
.policy__category {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
}
.policy__premium {
  background: linear-gradient(to right, #29c1e8, #27ce8f);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  color: #fff;
  font-size: 1rem;
}
.policy__premium--value {
  font-size: 2rem;
}
.policy__select {
  position: relative;
  top: 1.5rem;
  right: 10.5rem;
  width: 23px;
  height: 23px;
  z-index: 5;
  cursor: pointer;
}
.policy__selected-circle {
  position: relative;
  bottom: 6.5rem;
  right: 2rem;
  width: 100px;
  height: 100px;
  background: #40b1fe;
  border-radius: 50px;
}
.policy__logo-img {
  position: relative;
  bottom: 10rem;
  left: 8rem;
}
.policy__logo-img--summary {
  top: 2rem;
  left: 17rem;
}
.policy__img-header {
  width: 100%;
  margin: 5px;
}
.policy__text-desc {
  position: relative;
  bottom: 0px;
}
.policy__features {
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.policy__feature {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.policy__feature span {
  line-height: 1.3rem;
}
.policy__feature span:first-child {
  max-width: 12rem;
}
.policy__feature span:last-child {
  max-width: 8rem;
  text-align: right;
  color: #8b8b8b;
}
.policy__buttons {
  padding: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}
.policy--summary {
  height: 25rem;
}
.policy__age-groups {
  min-height: 16rem;
}

.policy:hover {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
}

.policy--selected {
  background-color: #fff;
  border: solid 1px #ebebeb;
  border-radius: 4px;
  height: 14rem;
  width: 25rem;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  z-index: 5;
}
.policy--selected p {
  margin-bottom: 0.5rem;
}
.policy--selected img {
  max-height: 2.5rem;
  max-width: 5rem;
  align-self: flex-end;
}

.policy-breakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  width: 28rem;
  font-size: 1.2rem;
  transition: 0.5s;
}
.policy-breakdown__premium {
  font-size: 14px;
}
.policy-breakdown hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(#ebebeb, #ebebeb);
  margin: 0 1.5rem 0 1.5rem;
}

.policy-breakdown:hover {
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.12);
}

.policy-breakdown-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.policy-list {
  display: flex;
  width: 96rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.policy-list--onboarding {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60vw;
}

.policy-list--selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.policy-list--selected__controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.policy-list--selected__close {
  cursor: pointer;
}

.service {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  width: 28rem;
  font-size: 1.4rem;
  padding: 1.2rem;
  overflow: hidden;
  transition: 0.3s;
  background-color: #fff;
}
.service__info {
  margin: 1.5rem;
  display: inline-flex;
  text-align: center;
}
.service__category {
  font-size: 1.5rem;
  font-weight: bold;
}
.service__img-header {
  width: 100%;
  height: 12rem;
}
.service__buttons {
  padding: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}

.service:hover {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
}

.service-breakdown:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.service-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.search-form {
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form__file-upload {
  overflow: hidden;
  position: relative;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  color: #8b8b8b;
  font-size: 0.8rem;
  cursor: pointer;
}
.form__file-upload input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form__wrapper {
  width: 23rem;
}
.form__inputs {
  display: flex;
  flex-direction: row;
}
.form__inputs--onboarding {
  text-align: left;
  width: 90%;
}
.form__input {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #fff;
  padding: 0.5rem;
  width: 22rem;
  font-size: 1.6rem;
}
.form__input::placeholder {
  color: #8b8b8b;
}
.form__input--long {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #fff;
  padding: 1rem;
  font-size: 1.6rem;
  width: 45rem;
}
.form__input--onboarding {
  border: 1px solid #40b1fe;
  border-radius: 4px;
  background-color: #f7fbff;
  padding: 0.7rem;
}
.form__input--transparent {
  background-color: transparent;
  width: 26rem;
}
.form__input--underline {
  border: 1px solid #ebebeb;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 0 1rem;
}
.form__input--read-only {
  border: none;
  background-color: #f9f9f9;
  font-family: "Proxima Nova Semibold";
  font-size: 1rem;
}
.form__input-group {
  display: flex;
  height: 3.8rem;
}
.form__input-group-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 35rem;
}
.form__input-group-submit {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 15rem;
}
.form__textarea {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #fff;
  padding: 1rem;
  width: 45rem;
  height: 10rem;
}
.form__label {
  display: block;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: "Proxima Nova Semibold";
  font-weight: bold;
}
.form__submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form__submit button {
  font-size: 2rem;
}

.notify-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailing-list {
  width: 50rem;
  justify-content: space-between;
}

.claim-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.2rem;
}
.claim-form__loss-type {
  width: 50%;
}

.modal {
  position: fixed;
  z-index: 500;
  background-color: #fff;
  min-width: 50%;
  max-height: 90%;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  text-align: left;
  padding: 5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}
.modal--selected-policies {
  position: fixed;
  z-index: 450;
  width: 70%;
  left: 15%;
  top: 75%;
  box-sizing: border-box;
  transition: all;
}
.modal--overflow {
  overflow-y: scroll;
  max-height: 90%;
}

.modal-error {
  color: white;
  min-height: 20px;
  margin-bottom: 1.5rem;
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}
.backdrop--bottom {
  top: 72%;
}

.SingleDatePickerInput__withBorder {
  border-radius: 4px;
  border: solid 1px #ebebeb;
  width: 22rem;
  height: 3.6rem;
}

.DateInput_input {
  height: 2.8rem;
  border-bottom: 0;
  font-size: 1.6rem;
}

.react-datepicker-popper {
  z-index: 100;
}

.Select {
  z-index: 10;
  width: 22rem;
}

.Select-control {
  height: 39px;
  border: 1px solid #ebebeb;
}

.Select.is-open {
  z-index: 100 !important;
}

.accordion {
  border: none;
  width: 80rem;
  line-height: 2.5rem;
}
.accordion__item {
  margin-bottom: 2rem;
  border-top: none;
  border-bottom: none;
}
.accordion__button {
  background-color: #fff;
  border: solid 1px #d5d5d5;
  border-radius: 4px;
  color: #27ce8f;
  padding: 1.8rem 4rem;
}
.accordion__button:hover {
  background-color: #fff;
}
.accordion__body {
  text-align: left;
  border: solid 1px #d5d5d5;
  border-top: none;
  padding: 1.8rem 4rem;
}

.user-card {
  border: solid 1px #40b1fe;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
}
.user-card__details {
  display: flex;
  flex-direction: column;
}
.user-card__img {
  object-fit: cover;
}
.user-card__name {
  font-size: 1.4rem;
}
.user-card__role {
  font-size: 1rem;
}

.rating-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rating-stars {
  display: flex;
  justify-content: center;
}

.rating-star {
  margin-top: 1rem;
  cursor: pointer;
  text-align: left;
}

.rating-column {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 20%;
}

.rating-caption {
  margin-top: 2rem;
  cursor: pointer;
  text-align: center;
  font-size: large;
}

.rating-remarks {
  display: flex;
  justify-content: center;
}

.rating-action {
  display: flex;
  justify-content: flex-end;
}

.rating-success {
  display: flex;
  justify-content: center;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.transition-group {
  position: relative;
}

.transition-helper {
  position: absolute;
}

.policy-selected {
  visibility: visible;
}

.policy-unselected {
  visibility: hidden;
}

/* MEDIA QUERIES */
@media (max-width: 767px) {
  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  .btn--navigation {
    width: 12rem;
  }
  .btn--width-lng {
    padding: 0.5rem 5rem 0.5rem 5rem;
    width: 100%;
  }

  .navbar--hidden {
    display: none;
  }

  .wrapper--lead {
    width: 85vw;
  }
  .wrapper--sub {
    max-width: 83.5vw;
  }

  .flex {
    flex-direction: column;
  }

  .reverse-md {
    flex-direction: column-reverse;
  }

  .policy--selected {
    height: 5rem;
    width: 100%;
    font-size: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .policy--selected img {
    align-self: unset;
  }

  .policy__logo-img {
    left: 7rem;
  }

  .policy__logo-img--summary {
    left: 13rem;
  }

  .policy-breakdown-list {
    flex-direction: column;
  }

  .policy--breakdown {
    width: 24rem;
    margin: 0 auto;
  }

  .policy-list {
    width: 30rem;
  }

  .policy-list--selected {
    flex-direction: column;
  }
  .policy-list--selected__controls {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer__tier1 {
    text-align: left;
  }
  .footer__tier1__detail {
    margin-right: 0rem;
    margin-left: 0rem;
    padding: 0;
  }
  .footer__tier1__link-group {
    margin: 0rem 0rem 3rem 0rem;
  }
  .footer__tier1__link-group h4 {
    margin-bottom: 1.5rem;
  }

  .social__items {
    justify-content: center;
  }

  .site-content__selling-points {
    flex-direction: column;
    width: 30rem;
  }
  .site-content__selling-point {
    flex-direction: column;
    width: 28rem;
    min-height: 30rem;
  }
  .site-content__selling-point img {
    align-self: auto;
    margin-top: 0;
  }
  .site-content__navigation {
    flex-direction: column;
    text-align: center;
  }
  .site-content__navigation-links {
    margin: 0 auto;
  }

  .bq-logo--footer {
    max-width: 10rem;
    max-width: 10rem;
  }

  .hero {
    max-width: 100%;
  }
  .hero__content {
    max-width: 90vw;
    line-height: 2rem;
  }

  .bq-banner {
    height: 12rem;
  }
  .bq-banner__items {
    height: 80%;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
  }

  .form__inputs {
    flex-direction: column;
  }
  .form__input-group-field {
    width: 15rem;
  }
  .form__input-group-submit {
    width: 10rem;
  }
  .form__textarea {
    width: 22rem;
  }

  .claim-form__loss-type {
    width: 100%;
  }

  .mailing-list {
    width: 28rem;
  }

  .modal {
    width: 80%;
    padding: 2.5rem;
  }
  .modal--selected-policies {
    top: 56%;
  }

  .modal-error {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .backdrop--bottom {
    top: 53%;
  }

  .auth__bounding-box {
    padding: 2.5rem;
    width: 95%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .accordion {
    width: 80vw;
  }

  .sidebar {
    flex-direction: row;
    width: 100%;
  }
  .sidebar__items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .sidebar__item {
    display: flex;
    justify-content: center;
    font-size: 1rem;
  }
  .sidebar__item--active {
    border-right: none;
    border-bottom: solid 2px #29c1e8;
  }

  .user-dash__main {
    margin-left: 0;
  }

  .motor_form_section {
    display: none;
  }
  .motor_floating_section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .motor_floating_section_button {
    margin: 2rem;
    position: fixed;
    bottom: 3rem;
  }
  .motor_partners_section {
    padding-left: 2rem;
    margin-bottom: 5rem;
  }
  .motor_banner {
    margin-left: 0rem;
  }
  .motor_banner img {
    max-width: 90%;
  }
  .motor_banner_title {
    max-width: 90%;
    text-align: center;
  }
  .motor_banner_tag {
    flex-direction: column;
  }
  .motor_banner_tag1 {
    margin-bottom: 3rem;
    margin-right: 0rem;
  }
  .motor_banner_tag2 {
    margin-left: 0rem;
  }
  .motor_form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .motor_form {
    min-width: 28rem;
    margin: 2rem 0;
    padding: 1.5rem;
  }
  .motor_form h3 {
    padding: 2rem;
    padding-bottom: 0;
  }
  .motor_partners {
    margin-bottom: 5rem;
  }
  .motor_partners_logo {
    width: 10rem;
    padding: 2rem;
  }

  .motor_compare_header {
    margin-bottom: 0;
  }
  .motor_compare_card {
    max-width: 90%;
    margin-bottom: 0;
    margin-top: 17rem;
  }
  .motor_compare_card img {
    width: 10rem;
  }
  .motor_compare_policies {
    margin-top: 9rem;
  }
  .motor_compare_policies_list_card {
    display: none;
  }
  .motor_compare_policies_list_card_mobile {
    display: flex;
  }
  .motor_compare_policies_list_help {
    display: none;
  }
  .motor_compare_policies_list_help_mobile {
    display: flex;
  }

  .get_help_card {
    flex-direction: column;
    margin: 0 3rem;
  }
  .get_help_card_caption {
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    padding-bottom: 3rem;
  }
  .get_help_card_caption h2 {
    padding: 1rem;
  }
  .get_help_card_caption p {
    padding: 1rem;
  }

  .travel_page_form {
    display: none;
  }
  .travel_page_floating {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .travel_page_floating_button {
    margin: 2rem;
    position: fixed;
    bottom: 0;
  }
  .travel_page_partners {
    padding-left: 2rem;
    margin-bottom: 5rem;
  }
  .travel_banner {
    margin-left: 0rem;
  }
  .travel_banner img {
    max-width: 90%;
  }
  .travel_banner_title {
    max-width: 90%;
    text-align: center;
  }
  .travel_banner_tag_item {
    width: 40%;
  }
  .travel_form {
    width: 90%;
    margin-left: 5%;
    margin-top: 2rem;
    padding: 1.5rem;
  }
  .travel_form h3 {
    padding: 2rem;
    padding-bottom: 0;
  }
  .travel_partners {
    margin-bottom: 5rem;
  }
  .travel_partners_logo {
    width: 10rem;
    padding: 2rem;
  }

  .travel_compare_header {
    margin-bottom: 0;
  }
  .travel_compare_card {
    max-width: 90%;
    margin-bottom: 0;
    margin-top: 17rem;
  }
  .travel_compare_card img {
    width: 10rem;
  }
  .travel_compare_policies {
    margin-top: 9rem;
  }
  .travel_compare_policies_cta {
    display: none;
  }
  .travel_compare_policies_cta_mobile {
    position: fixed;
    top: 3rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: #fff;
    box-shadow: 2px 2px 44px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2rem;
    color: rgba(0, 66, 65, 0.69);
    font-size: 1.8rem;
    font-weight: 700;
  }
  .travel_compare_policies_cta_mobile span {
    color: #009b9c;
  }
  .travel_compare_policies_cta_mobile button {
    margin-top: 2rem;
  }
  .travel_compare_policies_warning_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .travel_compare_policies_warning_mobile img {
    max-width: 20px;
    margin-right: 0.5rem;
    padding-top: 0;
  }
  .travel_compare_policies_warning_mobile p {
    padding-top: 1rem;
    color: #004241;
    font-weight: 700;
    font-size: 1.7rem;
  }
  .travel_compare_policies_list_card {
    display: none;
  }
  .travel_compare_policies_list_card_mobile {
    display: flex;
  }
  .travel_compare_policies_list_help {
    display: none;
  }
  .travel_compare_policies_list_help_mobile {
    display: flex;
  }

  .dashboard_navbar {
    justify-content: space-between;
  }
  .dashboard_navbar_left {
    display: flex;
    padding: 3rem;
  }
  .dashboard_nav_side {
    display: none;
  }
  .dashboard_nav_top {
    width: 100%;
  }
  .dashboard_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 10rem;
  }
  .dashboard_sidebar {
    align-items: flex-start;
    box-shadow: none;
  }
  .dashboard_sidebar_btn {
    display: none;
  }
  .dashboard_home_overview {
    justify-content: center;
  }
  .dashboard_home_overview_card {
    margin-bottom: 5rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }

  .navbar--hidden {
    display: none;
  }

  .social__items {
    justify-content: center;
  }

  .wrapper--lead {
    width: 60rem;
  }
  .wrapper--sub {
    max-width: 83.5vw;
  }

  .flex {
    flex-direction: column;
  }

  .reverse-md {
    flex-direction: column-reverse;
  }

  .policy__logo-img--summary {
    left: 10rem;
  }

  .policy-list {
    width: 64rem;
  }

  .site-content__selling-points {
    flex-direction: column;
    width: 64rem;
  }
  .site-content__selling-point {
    width: 30rem;
    min-height: 31.2rem;
  }
  .site-content__navigation {
    flex-direction: column;
    text-align: center;
  }

  .hero {
    color: #fff;
    max-width: 70%;
  }
  .hero__content {
    max-width: 90vw;
    line-height: 2rem;
  }

  .modal {
    width: 75%;
  }

  .accordion {
    width: 80vw;
  }

  .sidebar {
    flex-direction: row;
    width: 100%;
  }
  .sidebar__items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .sidebar__item {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
  }
  .sidebar__item--active {
    border-right: none;
    border-bottom: solid 2px #29c1e8;
  }

  .user-dash__main {
    margin-left: 0;
  }
}
@media (min-width: 1025px) {
  .navbar__menu-button {
    display: none;
  }

  .site-content__selling-points {
    flex-direction: column;
  }
}

/*# sourceMappingURL=styles.css.map */
